import { HomePage, LoginPage } from "./components";

const App = () => {
  return (
    <div className="App">
      <h1>This a test!</h1>
      <LoginPage />
    </div>
  );
};

export default App;
